.gutegruende-wrapper {
    max-width: 1200px;
    width: 100%;
    background-color: #FFFFFF;
    padding: 10px;
    margin: 3em auto 3em auto;
}

.gutegruende-wrapper h2 {
    font-size: xx-large;
}

.gutegruende-wrapper h3 {
    font-size: large;
}

.gutegruende-wrapper h4 {
    font-size: x-large;
}

.gutegruende-wrapper .content {
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 21px;
}
.gutegruende-wrapper .button-wrapper-cust {
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    font-size: 21px;
}

.gutegruende-wrapper ul {
    font-size: 21px;
    list-style-image: url(https://germanzero.de/assets/G0_Logo_Pfeil.png);
}

.gutegruende-wrapper .intro {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 2fr 1fr;
}
@media screen and (max-width: 900px) {
    .gutegruende-wrapper .intro {
        grid-template-columns: 1fr;
    }
  }



.gutegruende-wrapper .intro .left {
    grid-column: auto / span 1;
    padding-top: 2em;
}


.gutegruende-wrapper .intro .right {
    grid-column: auto / span 1;
    align-items: center;
    width: 100%;
}

.gutegruende-wrapper .img-wrapper {
    max-width: 800px;
    margin: auto;
    padding-top: 10px;
}
.gutegruende-wrapper .img-wrapper p {
    font-size: medium;
    width: 100%;
    text-align: right;
}

.gutegruende-wrapper .berichte {
    width: 100%;
}

.gutegruende-wrapper .berichte .kachel {
    margin-top: 2em;
    width: 100%;
    background-color: #FFF9E5;
    border: solid #FFC80C thin;
    padding: 1em;

}