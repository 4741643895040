.supporter-container {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.supporter-container h1 {
    text-align: center;
}


.supporter-container .kachel-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    row-gap: 20px;
    column-gap: 20px;
    background-color: #FFF9E5;
    padding: 5px;
}
@media screen and (max-width : 900px) {
    .supporter-container .kachel-container {
        grid-template-columns: repeat(1,1fr);
    }
}

.supporter-container .kachel-container .kachel {
    grid-column: auto / span 1;
    width: 300px;
    min-height: fit-content;
    max-height: 200px;
    height: 100%;
    border: solid gray;
    padding: 5px;
    background-color: white;

}

.supporter-container .kachel-container .img-container {
    display:flex;
    align-items: center;
    width: 100%;
    height: 80%;
    vertical-align: middle;
}
.supporter-container .kachel-container .kachel .img-container img {
    max-width: 100%;
    max-height: 150px;

}

.supporter-container .kachel-container .kachel h6 {
    font-size: larger;
}

.supporter-container .andsoon {
    font-size: 100px;
}

.supporter-container #remark {
    font-size: small;
    text-align: center;
    margin-top: 1em;
}

