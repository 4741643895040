.intro {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 2fr 1fr;
}

@media screen and (max-width: 900px) {
    .intro {
        grid-template-columns: 1fr;
    }
}

.intro .left {
    grid-column: auto / span 1;
    padding-top: 4em;
}

.intro .right {
    grid-column: auto / span 1;
    align-items: center;
    width: 100%;
    padding-top: 3em;
}