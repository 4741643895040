.english-summary-wrapper {
    width: 100%;
}

.english-summary-wrapper h1 {
    margin-top: 1em;
    /* font-size: xx-large; */
}

.english-summary-wrapper h3 {
    font-size: x-large;
}
.english-summary-wrapper h4 {
    font-size: larger;
}

.english-summary-wrapper ul {
    list-style-image: url(https://radentscheid-goe.de/img/icons/G0_Logo_Pfeil.png);
}

.english-summary-wrapper .section-container {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.english-summary-wrapper .section-container .border-box {
    border: solid thick #FFC80C;
    padding: .5em;
    margin-bottom: .5em;
}


