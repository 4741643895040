.faq-wrapper {
    background-color: #FFFFFF;
    width: 100%;
    margin-top: 3em;
    margin-bottom: 3em;
}

.faq-wrapper h1 {
    text-align: center;
}
.faq-wrapper h3 {
    text-align: left;
    font-size: x-large;
    margin-right: 2em;
}

.faq-wrapper .question {
    background-color: #FFF9E5;
    padding: 20px;
    width: 100%;
    margin-bottom: 15px;
}

.faq-wrapper .question ul {
    font-size: large;
}

.faq-wrapper .question p {
    font-size: large;
}

.faq-wrapper .question .heading {
    display: flex;
    justify-content: space-between;
}


/* .faq-wrapper .question a {
    text-decoration: none;
    -webkit-text-decoration: none;
} */