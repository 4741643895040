.line-wrapper {
    width: 100%;
    
}

.line-wrapper hr {
    width: 40%;
    border: 10px solid #FFC80C;
}

.section-img {
    width: 100%;
    height: 100px;
}