.flyer {
    width: 100%;
    /* font-size: 21px; */
}
.flyer .section {
    margin-top: 5vh;
    margin-bottom: 5vh;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    /* padding: 2em; */
    /* border: solid green; */
}


.flyer .section .float-container {
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20px;
}

.flyer .section img{
    width: 100%;
    height: auto;
}


.flyer ul {
    list-style-image: url(https://radentscheid-goe.de/img/icons/G0_Logo_Pfeil.png);
}

.flyer ul#highlight {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.5;
}



.flyer p#highlight {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    border: solid green;
    border-radius: 1em;
    background-color: rgba(144, 238, 144, 0.413);
    text-align: center;
}

.flyer .flex {
    display: flex;
    width: 100%;
}

.flyer .header {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 2em;
    width: 100%;
    padding-bottom: 1em;
    align-items: center;
}
.flyer .header h1 {
    grid-column: 1 / span 1;
    height: 100%;
    display: flex;
    align-items: center;
}

.flyer .header .img-container {
    height: 100%;
    display: flex;
    align-items: center;
}
.flyer .header img {
    grid-column: 2 / span 1;
    height: auto;
    max-width: 80%;
    align-items: center;
}


.flyer button {
    border-radius: .5em;
    border: none;
}
.flyer .green {
    background-color: lightgreen;
}

.flyer img {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}


.flyer .img-center {
    margin-left: auto;
    margin-right: auto;
}

.flyer .blue {
    background-color: lightblue;
}

@media screen and (max-width: 900px) {
    .flyer h1{
        font-size: x-large;
    }
}