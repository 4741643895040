.custom-adv-wrapper .inputs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1em;
    column-gap: 1em;
    grid-template-rows: max-content;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.custom-adv-wrapper .inputs .question {
    grid-column: auto / span 1;
    grid-row: auto;
    margin-top: 1em;
}

.custom-adv-wrapper .inputs .question p {
    margin-top: 0px;
    padding-top: 0px;
    height: 100%;
}

.custom-adv-wrapper .inputs .slider {
    grid-column: auto / span 1;
    width: 100%;
    margin-bottom: 1em;
    align-items: center;
}

.custom-adv-wrapper .inputs .submitter {
    grid-column: auto;
    width: 100%;
    margin-bottom: 1em;
}


@media screen and (max-width: 900px) {
    .custom-adv-wrapper .inputs {
        grid-template-columns: 1fr;
    }
    .custom-adv-wrapper .inputs .question {
        grid-column: auto / span 1;
    }
    .custom-adv-wrapper .inputs .slider {
        grid-column: auto / span 1;
    }
  }