.intro-neu-wrapper {
    width: 100%;
    height: 70vh;
    background-image: url("/public/img/intro_background/wallpaper.jpg");
    background-size: cover;
    /* background-repeat: no-repeat; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-bottom: 5vh;
}
.intro-neu-wrapper .vertical-flex {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
} 
.intro-neu-wrapper .vertical-flex .horizontal-flex {
    width: 100%;
    height: fit-content;
    align-items: center;
    /* border: blue solid; */
}

.intro-neu-wrapper h1{
    margin: .3em;
    padding: 0.2em;
    background-color: white;
    width: fit-content;
    height: fit-content;
}

.intro-neu-wrapper h3{
    margin: .3em;
    padding: 0.4em;
    color: white;
    width: fit-content;
    height: fit-content;
    font-size: medium;
}

.intro-neu-wrapper .img-wrapper {
    padding: 5%;
}
.intro-neu-wrapper img{
    width: 100%;
    max-width: 400px;
    display: block;
}

.intro-neu-wrapper .easy-offers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    column-gap: 1em;
    row-gap: 1em;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 3em;
}

.intro-neu-wrapper .easy-offers .offer {
    grid-column: auto / 1 span;
    /* margin-top: 3em; */
    padding: 1em;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.571) ;
}

.intro-neu-wrapper .easy-offers .offer h6 {
    font-size: 21px;
}

@media screen and (max-width: 700px) {
    .intro-neu-wrapper {
        height: fit-content;
    }
    .intro-neu-wrapper .vertical-flex {
        align-items: flex-start;
    }
    .intro-neu-wrapper .easy-offers {
        grid-template-columns: 1fr;
    }
    .intro-neu-wrapper .easy-offers .offer p {
        display: none;
        visibility: hidden;
    }
}

