.podcast-wrapper {
    width: 100%;
    background-color: white;
    border: solid thin #FFC80C;
    padding: 2em;
    margin-top: 2em;
}

.podcast-wrapper .img-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.podcast-wrapper .img-wrapper img {
    width: 95%;
    max-width: 600px;
}

.podcast-wrapper .button-line-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 1em;
}
@media  screen and (max-width: 800px) {

    .podcast-wrapper .button-line-wrapper {
        grid-template-columns: repeat(1,1fr);
    }
}

.podcast-wrapper .button-line-element {
    grid-column: auto / span 1;
}


.podcast-wrapper p {
    font-size: large;
}
.podcast-wrapper h3 {
    font-size: xx-large;
}
.podcast-wrapper h5 {
    font-size: x-large;
    margin-top: 1em;
}

.podcast-wrapper h6 {
    margin-top: 1em;
    font-size: larger;
}