.dashboard-wrapper {
    background-color: #FFF9E5;
    width: 100%;
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
    list-style-image: url(https://radentscheid-goe.de/img/icons/G0_Logo_Pfeil.png);
}

.dashboard-wrapper .content-wrapper {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1em;
}

.dashboard-wrapper hr {
    border: 10px solid #FFC80C;
    width: 20%;
}

.dashboard-wrapper .grid-board {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
}
@media screen and (max-width: 900px) {
    .dashboard-wrapper .grid-board {
        grid-template-columns: 1fr;
    }
  }

.dashboard-wrapper h1 {
    margin-bottom: 0px;
}

.dashboard-wrapper h4 {
    font-size: 21px;
    margin-bottom: 0.3em;
    margin-top: 0.3em;
    padding-bottom: 0px;
}

.dashboard-wrapper .timechart-wrapper {
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 400px;
}

.dashboard-wrapper .analysis-wrapper{
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.dashboard-wrapper .analysis {
    display: block;
}

.dashboard-wrapper .analysis button {
    background-color:#FFC80C;
    border: none;
    border-radius: 5%;
    box-shadow: black 2px;
}

.dashboard-wrapper .analysis .deep-dive {
    text-align: left;
    padding: 20px;
    margin: 10px;
    border-top: solid rgb(226, 226, 223) thick
}

.dashboard-wrapper .deep-dive#hidden {
    visibility: hidden;
    height: 0px;
}

.dashboard-wrapper .deep-dive#visible {
    visibility: visible;
    height: fit-content;
}

.dashboard-wrapper .subtext {
    max-width: 800px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2em;
}