.newsletter-wrapper {
    padding-top: 1em;
    padding-bottom: 2em;
}

.newsletter-wrapper input {
    height: 2em;
    /* border: solid thin grey */
}

.newsletter-wrapper h1 {
    text-align: center;
}

.newsletter-wrapper h2 {
    font-size: xx-large;
    text-align: center;
}


/* .newsletter-wrapper div {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
} */

.newsletter-wrapper .erfolg {
    border: solid thick green;
    border-radius: 15px;
    background-color: lightgreen;
    color: green;
    justify-content: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.newsletter-wrapper .erfolg p {
    text-align: center;
    padding-top: .5em;
    padding-bottom: .5em;
}

.newsletter-wrapper .fehler {
    border: solid thick red;
    border-radius: 15px;
    background-color: lightpink;
    color: black;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.newsletter-wrapper .fehler p {
    text-align: center;
    padding-top: .5em;
    padding-bottom: .5em;
}

.newsletter-wrapper .letters h2 {
    font-size: x-large;
    text-align: left;
}

.newsletter-wrapper .letters .kachel {
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
    background-color: #FFF9E5;
    border: solid #FFC80C thin;
    padding: 1em;
}

.newsletter-wrapper .letters .kachel h3 {
    font-size: large;
}

.newsletter-wrapper .letters .kachel:hover{
    transform: scale(0.97);
    transition: ease 400ms;
}
