.video-antworten-wrapper {
    width: 100%;
}

.video-antworten-wrapper h6 {
    font-size: larger;
    height: 4em;
}

.video-antworten-wrapper .video-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 50px;
}

.video-antworten-wrapper .video-grid .video-wrapper {
    padding: 1em;
    border: solid #FFC80C thin;
    background-color: #ffc60c41;
    columns: auto /span 1;
}

@media screen and (max-width: 900px) {
    .video-antworten-wrapper .video-grid {
        grid-template-columns: repeat(1, 1fr);
    }

}

.video-antworten-wrapper .noch-nicht-gestellt {
    margin-top: 2em;
    margin-bottom: 2em;
}