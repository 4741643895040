.custom-adv-background {
    width: 100%;
    background-color: lavender ;
    margin-top: 3em;
}

.custom-adv-wrapper {
    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left:  1em;
    padding-right:  1em;

}

.custom-adv-wrapper .header {
    width: 100%;
    margin-bottom: 2em;
}
.custom-adv-wrapper .header .sub {
    display: grid;
    grid-template-columns: 70% 20%;
    column-gap: 1em;
    width: 100%;
}
@media screen and (max-width: 900px){
    .custom-adv-wrapper .header .sub {
        grid-template-columns: 1fr;
    }
}

.custom-adv-wrapper .header .sub .el {
    grid-column: auto / span 1;
    margin-left: auto;
    margin-right: auto;
}
.custom-adv-wrapper .header .sub .el p {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.custom-adv-wrapper .header .sub img {
    max-width: 100%;
    max-height: 4em;

}


.custom-adv-wrapper .header h3 {
    font-size: large;
}



