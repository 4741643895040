.faqpage-wrapper {
    max-width: 1200px;
    width: 100%;
    background-color: #FFFFFF;
    padding: 10px;
    margin: 3em auto 3em auto;
}

.faqpage-wrapper h2 {
    font-size: xx-large;
}

.faqpage-wrapper h3 {
    font-size: large;
}

.faqpage-wrapper .intro {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 2fr 1fr;
}
@media screen and (max-width: 900px) {
    .faqpage-wrapper .intro {
        grid-template-columns: 1fr;
    }
  }



.faqpage-wrapper .intro .left {
    grid-column: auto / span 1;
    padding-top: 2em;
}


.presse-wrapper .intro .right {
    grid-column: auto / span 1;
    align-items: center;
    width: 100%;
}

.presse-wrapper .img-wrapper {
    max-width: 800px;
    margin: auto;
    padding-top: 10px;
}
.presse-wrapper .img-wrapper p {
    font-size: medium;
    width: 100%;
    text-align: right;
}

.presse-wrapper .berichte {
    width: 100%;
}

.presse-wrapper .berichte .kachel {
    margin-top: 2em;
    width: 100%;
    background-color: #FFF9E5;
    border: solid #FFC80C thin;
    padding: 1em;

}