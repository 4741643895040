.slideshow-wrapper {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.slideshow-wrapper .each-slide-effect {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 400px;
}
  
.slideshow-wrapper .each-slide-effect img {
    height: 400px;
}