.slidecontainer {
    width: 100%; /* Width of the outside container */
  }
  
  /* The slider itself */
  .slidecontainer   .slider {
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 25px; /* Specified height */
    background: #FFC80C; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
  }
  
  /* Mouse-over effects */
  .slidecontainer   .slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .slidecontainer   .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 10px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: black; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
  
  .slidecontainer   .slider::-moz-range-thumb {
    width: 10px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: black; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }


.catslider-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 3px;
  column-gap: 3px;
  background: None;
  margin-top: 0px;
  padding-top: 0px;
}
.catslider-container .cat {
  grid-column: auto / span 1;
}
.catslider-container .cat button {
  width: 100%;
  height: 3em;
  border-radius: 10px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.catslider-container .cat button#on {
  background: #ffc60c;
  border: solid thin black;
}
.catslider-container .cat button#off {
  background: white;
}


.dropdown-container {
  width: 100%;
}
.dropdown-container button {
  width: 100%;
  height: 3em;
  border-radius: 10px;
}

.dropdown-container button#on {
  background: #ffc60c;
  border: solid thin black;
}
.dropdown-container button#off {
  background: white;
}
