.list-block-container {
    margin-top: 4em;
}

.list-block-container h1 {
    margin-bottom: 2em;
    text-align: center;
}

.list-block-container .grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 6em;
    row-gap: 4em;
}

.list-block-container .grid .column {
    max-width: 400px;
    margin: auto;
    height: 100%;
    min-height: fit-content;
}

.list-block-container .grid .column .flex {
    display: flex;
    vertical-align: left;
}
.list-block-container .grid .column .flex img {
    height: 1em;
    width: 1em;
    margin: 0px;
    border: none;
    border-radius: 0px;
}

.list-block-container .grid .column img{
    max-width: 200px;
    width: 50%;
    border-style: solid gray thin;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-width: 5pt;
}
.list-block-container img#linked:hover {
    border-style: solid;
    border-color: #FFC80C;
}
.list-block-container .grid .column h4 {
    margin-top: 0.5em;
    font-size: x-large;
}
.list-block-container .grid .column p {
    margin-top: 0.2em;
}

@media screen and (max-width: 900px) {
    .list-block-container .grid {
        grid-template-columns: 1fr;
    }
    /* .list-block-container p{
        font-size: medium;
    } */
    .list-block-container .grid .column img {
        max-width: 30%;
    }
}