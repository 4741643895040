.kosten-wrapper {
    width: 100%;
    background-color: white;
    border: solid thin #FFC80C;
    padding: 2em;
    margin-top: 2em;
}

.kosten-wrapper table {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
}

.kosten-wrapper table td {
    text-align: center;
}

.kosten-wrapper img {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.kosten-wrapper p {
    font-size: large;
}
.kosten-wrapper h3 {
    font-size: xx-large;
}
.kosten-wrapper h5 {
    font-size: x-large;
    margin-top: 1em;
}

.kosten-wrapper h6 {
    margin-top: 1em;
    font-size: larger;
}

.kosten-wrapper .links {
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 900px) {
    .kosten-wrapper {
        padding: .5em;
    }
    .kosten-wrapper h3 {
        font-size: x-large;
    }
    .kosten-wrapper h5 {
        font-size: larger;
        margin-top: 1em;
    }
    .kosten-wrapper h6 {
        font-size: larger;
    }
    .kosten-wrapper p {
        font-size: medium;
    }
    .kosten-wrapper li {
        font-size: medium;
    }

    .kosten-wrapper td {
        font-size: smaller;
        border: solid thin;
    }
    .kosten-wrapper th {
        font-size: smaller;
        border: solid thin;
    }
    
}