.flyerpage-wrapper {
    max-width: 1200px;
    width: 100%;
    background-color: #FFFFFF;
    padding: 1em;
    margin: 3em auto 3em auto;
}

.flyerpage-wrapper h2 {
    font-size: xx-large;
}

.flyerpage-wrapper h3 {
    font-size: large;
}

.flyerpage-wrapper .intro {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 10vh;
}

.flyerpage-wrapper .intro .left {
    grid-column: auto / span 1;
    display: flex;
    align-items: center;
    height: 100%;
    /* border: solid green; */
    padding-top: 2em;
    padding-bottom: 2em;
}

.flyerpage-wrapper .intro .right {
    grid-column: auto / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.flyerpage-wrapper .intro .right img {
    width: 100%;
    max-width: 30vh;
    align-items: center;
}



@media screen and (max-width: 900px) {
    .flyerpage-wrapper .intro {
        grid-template-columns: 1fr;
    }
    .flyerpage-wrapper h1 {
        font-size: xx-large;
    }
    .flyerpage-wrapper h2 {
        font-size: x-large;
    }
  }