.youtube-wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
}

.youtube-wrapper .video {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: fit-content;
    align-items: center;
}

.youtube-wrapper .video iframe {
    width: 100%;
}
